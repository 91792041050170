export const EVENTS = {
  // getHeaderInfo is resolve
  GET_HEADER_INFO_LOADED: 'GET_HEADER_INFO_LOADED',
  UPDATE_HEADER_INFO: 'UPDATE_HEADER_INFO',
  // compare list is changed
  UPDATE_COMPARE_LIST: 'UPDATE_COMPARE_LIST',
  // wish list is changed
  UPDATE_WISH_LIST: 'UPDATE_WISH_LIST',
  // cart data is changed
  CART_UPDATE: 'CART_UPDATE',
  // add products to cart
  ADD_PRODUCTS: 'ADD_PRODUCTS',
  // loaded data for ViewedProducts
  VIEWED_PRODUCTS_INITIALIZED: 'VIEWED_PRODUCTS_INITIALIZED',
  // event when main ES6 app is init
  APP_IS_INIT: 'APP_IS_INIT',
  // init ProductStores
  PRODUCT_STORES_INITIALIZED: 'PRODUCT_STORES_INITIALIZED',
  // loaded delivery data for Product
  DELIVERY_DATA_PRODUCT_LOADED: 'DELIVERY_DATA_PRODUCT_LOADED',
  // loaded services data for Product
  SERVICES_DATA_PRODUCT_LOADED: 'SERVICES_DATA_PRODUCT_LOADED',
  // show auth modal
  SHOW_AUTH_MODAL: 'SHOW_AUTH_MODAL',
  // show product image modal
  SHOW_PRODUCT_IMG_MODAL: 'SHOW_PRODUCT_IMG_MODAL',
  // hide product image modal
  HIDE_PRODUCT_IMG_MODAL: 'HIDE_PRODUCT_IMG_MODAL',
  CHANGE_PRODUCT_IMG_SLIDER: 'CHANGE_PRODUCT_IMG_SLIDER',

  SHOW_MOBILE_FIXED_HEADER: 'SHOW_MOBILE_FIXED_HEADER',

  REMOVED_DESIRE_ITEMS: 'REMOVED_DESIRE_ITEMS',

  HIDE_MOBILE_FIXED_HEADER: 'HIDE_MOBILE_FIXED_HEADER',
  PRODUCT_COUNT_CHANGED: 'productsCountChanged',
  PRODUCT_WAS_REMOVED: 'PRODUCT_WAS_REMOVED',
  GET_HEADER_INFO_LOAD:  'getHeaderInfoLoad',
  SET_CART_PRODUCTS_COUNT: 'setCartProductsCount',
  ADD_CART_PRODUCTS: 'addCartProducts',
  ADD_CART_PRODUCTS_NO_CART_MODAL: 'addCartProductsNoCartModal',
  WINDOW_APP_IS_INIT: 'APP_IS_INIT',
  // loaded data for TopProducts
  TOP_PRODUCTS_INITIALIZED: 'TOP_PRODUCTS_INITIALIZED',
};

export const STORAGE_EVENT = {
  ON_CHANGE_STORE: 'storeChanged',
};

export const CUSTOM_SELECT_EVENT = {
  HAS_CHANGES: 'HAS_CHANGES',
  OPTIONS_WAS_CHANGED: 'OPTIONS_WAS_CHANGED',

  DELIVERY_BRANCH_OPTIONS_WAS_CHANGED: 'DELIVERY_BRANCH_OPTIONS_WAS_CHANGED',
  SHOP_OPTIONS_WAS_CHANGED: 'SHOP_OPTIONS_WAS_CHANGED',
  DELIVERY_TYPE_WAS_CLEARED: 'DELIVERY_TYPE_WAS_CLEARED',

  BIRTHDAY_DAY_TYPE: 'BIRTHDAY_DAY_TYPE',
  BIRTHDAY_MONTH_TYPE: 'BIRTHDAY_MONTH_TYPE',
  BIRTHDAY_YEAR_TYPE: 'BIRTHDAY_YEAR_TYPE',

  CITY_TYPE: 'CITY_TYPE',
  DELIVERY_TYPE_TYPE: 'DELIVERY_TYPE_TYPE',
  DELIVERY_BRANCH_TYPE: 'DELIVERY_BRANCH_TYPE',
  STREET_TYPE: 'STREET_TYPE',
  NOVA_POSHTA_TYPE: 'NOVA_POSHTA_TYPE',
  JUSTIN_TYPE: 'JUSTIN_TYPE',
  SHOP_TYPE: 'SHOP_TYPE',
};

export const FORM_EVENT = {
  MAIN_DATA_FORM_HAS_CHANGES: 'MAIN_DATA_FORM_HAS_CHANGES',
  MAIN_DATA_FORM_HIDDEN: 'MAIN_DATA_FORM_HIDDEN',

  COMPANY_DATA_FORM_HAS_CHANGES: 'COMPANY_DATA_FORM_HAS_CHANGES',
  COMPANY_DATA_FORM_HIDDEN: 'COMPANY_DATA_FORM_HIDDEN',

  ADDRESS_FORM_WAS_SUBMITED: 'ADDRESS_FORM_WAS_SUBMITED',
  NEW_ADDRESS_WAS_ADDED: 'NEW_ADDRESS_WAS_ADDED',
  ADDRESS_WAS_REMOVED: 'ADDRESS_WAS_REMOVED',

  CREATED_ADDITIONAL_PHONE_FORM_SUBMITED: 'CREATED_ADDITIONAL_PHONE_FORM_SUBMITED',
  EDIT_ADDITIONAL_PHONE_FORM_SUBMITED: 'EDIT_ADDITIONAL_PHONE_FORM_SUBMITED',
  MAIN_PHONE_FORM_SUBMITED: 'MAIN_PHONE_FORM_SUBMITED',

  CREATED_ADDITIONAL_PHONE_CODE_CONFIRMED: 'CREATED_ADDITIONAL_PHONE_CODE_CONFIRMED',
  EDIT_ADDITIONAL_PHONE_CODE_CONFIRMED: 'EDIT_ADDITIONAL_PHONE_CODE_CONFIRMED',
  MAIN_PHONE_CODE_CONFIRMED: 'MAIN_PHONE_CODE_CONFIRMED',

  COMPANY_PHONE_CODE_CONFIRMED: 'COMPANY_PHONE_CODE_CONFIRMED',

  EMAIL_FORM_HAS_CHANGES: 'EMAIL_FORM_HAS_CHANGES',

  FILE_WAS_REMOVED: 'FILE_WAS_REMOVED',
  FILE_WAS_SUBMITED: 'FILE_WAS_SUBMITED',

  REMOVE_EMPTY_FORM: 'REMOVE_EMPTY_FORM',
  DELETE_THIS_FORM: 'DELETE_THIS_FORM',
  NEW_COMPANY_WAS_ADDED: 'NEW_COMPANY_WAS_ADDED',

  DELETE_DEFAULT_COMPANY_DATA: 'DELETE_DEFAULT_COMPANY_DATA',

  SAVE_BIRTHDAY: 'SAVE_BIRTHDAY',
};

export const CART_LIMITS = {
  DEFAULT_PURCHASE_LIMIT: 0,
};